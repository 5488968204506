import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import { geographicalService } from "@/services/geographical.service";

import { policyList } from "@/enums/permissions";
import {
  DynamicFormComponent,
  FormField,
  FormFieldTypes,
  TableFieldType,
  TableHeader
} from "helix-vue-components";
export const vendorForm: FormField[] = [
  {
    component: FormFieldTypes.textField,
    label: i18n.t("name").toString(),
    name: "name",
    options: {
      flex: 4,
      row: 1,
      validationRules: ["required"]
    }
  },
  {
    component: FormFieldTypes.autocomplete,
    label: i18n.t("type").toString(),
    name: "type",
    options: {
      validationRules: ["required"],
      associationText: "text",
      associationValue: "value",
      flex: 4,
      row: 1,
      selectOptions: () => [
        { value: "VENDOR", text: i18n.t("vendor") },
        { value: "LABORATORY", text: i18n.t("laboratory") }
        /*{ value: "WHOLESALER", text: i18n.t("wholesaler") },
              { value: "MANUFACTURER", text: i18n.t("vendors.manufacturer") },
              { value: "PRODUCER", text: i18n.t("vendors.producer") },
              { value: "RETAILER", text: i18n.t("retailer") } */
      ]
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("license_number").toString(),
    name: "license",
    options: {
      validationRules: ["required"],
      flex: 4,
      row: 1
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("street_address").toString(),
    name: "address1",
    options: {
      validationRules: ["required"],
      flex: 4,
      row: 2
    }
  },
  {
    component: FormFieldTypes.autocomplete,
    label: i18n.t("country").toString(),
    name: "country",
    options: {
      dependEmitField: "state",
      placeholder: i18n.t("type_to_search"),
      validationRules: ["required"],
      selectOptions: async () => await geographicalService.getCountries(),
      associationValue: "name",
      associationText: "name",
      flex: 4,
      row: 2
    }
  },
  {
    component: FormFieldTypes.autocomplete,
    label: i18n.t("state").toString(),
    name: "state",
    options: {
      dependOnField: "country",
      placeholder: i18n.t("type_to_search"),
      selectOptions: (scope: DynamicFormComponent) => {
        return geographicalService.getStates(
          scope.currentModel!.country || "United States"
        );
      },
      associationText: "name",
      associationValue: "name",
      validationRules: ["required"],
      flex: 2,
      row: 2
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("zip_code").toString(),
    name: "zip",
    options: {
      flex: 2,
      row: 2
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("city").toString(),
    name: "city",
    options: {
      validationRules: ["required"],
      flex: 4,
      row: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("website").toString(),
    name: "website",
    options: {
      flex: 4,
      row: 3
    }
  },
  {
    component: FormFieldTypes.textField,
    label: i18n.t("main_phone").toString(),
    name: "phone1",
    options: {
      flex: 4,
      row: 3
    }
  }
];

export const VendorsTableMetadata: TableHeader[] = [
  {
    value: "name",
    label: i18n.t("name").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "type",
    label: i18n.t("type").toString(),
    fieldComponent: TableFieldType.enumText,
    enum: {
      VENDOR: i18n.t("vendor").toString(),
      MANUFACTURER: i18n.t("vendors.manufacturer").toString(),
      LABORATORY: i18n.t("laboratory").toString()
    },
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    value: "city",
    label: i18n.t("city").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string,
    sortable: true
  },
  {
    value: "phone1",
    label: i18n.t("phone").toString(),
    class: "tdt__headers__fieldLong",
    fieldComponent: TableFieldType.string,
    sortable: false
  }
];
