import ChoiceModal from "@/components/locations/importExportWizard/choiceModal/ChoiceModal.component";
import { ResetService } from "@/decorators/resetService.decorator";
import { policyList } from "@/enums/permissions";
import { HttpQuery } from "@/interfaces/httpQuery";
import { Vendor } from "@/interfaces/vendor";
import { pusherEvents } from "@/internal";
import { VendorsTableMetadata } from "@/metadata/vendor";
import { vendorActionService } from "@/services/vendor.action.service";
import { vendorService } from "@/services/vendor.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  ActionSubheader,
  BooleanCheck,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import Template from "./vendorList.template.vue";

const namespace: string = "VendorModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  },
  inject: ["$changes"]
})
@ResetService(vendorService)
export default class VendorListComponent extends Vue {
  @Getter("vendors", { namespace })
  public vendors!: Vendor[];
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Action("loadVendors", { namespace })
  public getVendorsAction!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public showFilters = false;
  public activeFilter = false;
  public enableMultiActions = true;
  public headers: TableHeader[] = VendorsTableMetadata;
  public generalActions: ActionSubheader[] = vendorActionService.getGeneralActions(
    this.openCsvModal,
    this.filtersCallback
  );
  public rowActions: TableAction[] = vendorActionService.getRowActions();
  public multiActions: TableAction[] = vendorActionService.getMultiActions();

  public openCsvModal() {
    this.$modals.load(
      ChoiceModal,
      {
        size: "fit",
        positionY: "top"
      },
      { csvType: "vendors-laboratories" }
    );
  }

  public filtersCallback() {
    this.showFilters = !this.showFilters;
    if (!this.showFilters && !this.activeFilter) {
      this.onVendorFilter(!this.showFilters);
    }
  }

  public async triggerSearch(query?: HttpQuery) {
    vendorService.setQuery(query);
    this.getVendorsAction();
  }

  /**
   * Triggers search for qualified/non qualified products.
   * @param header: TableHader
   */
  public onVendorFilter(active: boolean) {
    this.activeFilter = active;
    const param = active
      ? { "q[is_active]": 1 }
      : { "q[is_active_is_false]": 1 };
    this.triggerSearch(param);
  }

  public changePagination(pagination: TablePagination) {
    const paginate = vendorService.paginationAction();
    paginate(pagination);
  }

  public filter(header: TableHeader) {
    vendorService.sortQuery(header);
  }

  protected mounted() {
    this.setPageNav({
      title: "vendors.view_title",
      rightActions: {
        generalActions: () => this.generalActions
      }
    });
    this.getVendorsAction(true);
    this.enableMultiActions = this.hasPermission(
      policyList.enableDisableVendorsLabs
    );
    this.$changes.watch(pusherEvents.vendorTouched, this.getVendorsAction);
  }

  protected beforeDestroy() {
    vendorService.resetQuery();
  }
}
